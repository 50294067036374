import { iconStarSolid } from '@wfp/icons';
import { Icon } from '@wfp/ui';
import React from 'react';

import TableMain from 'src/components/TableMain';
import OperationIdCell from '../OperationIdCell';

const ReviewStatusTab = ({
  data,
  filterText,
  filterSelect,
  generateReviewIcons,
  loading,
}) => {
  const columns = [
    {
      Header: 'Operation ID',
      accessor: 'project_code',
      id: 'project_code_column',
      Filter: column => filterText(column),
      Cell: ({ row }) => <OperationIdCell row={row} />,
    },
    {
      Header: 'Regional Bureau',
      accessor: 'project_regional_bureau_display_code',
      id: 'rb_column',
      Filter: column =>
        filterSelect('project_regional_bureau_display_code', column),
    },
    {
      Header: 'Country',
      accessor: 'project_country',
      id: 'project_country_column',
      filter: 'equals',
      Filter: column => filterSelect('project_country', column, true),
    },
    {
      Header: 'Review group',
      accessor: 'review_status',
      id: 'review_status_column',
      Filter: column => filterText(column),
    },
    {
      Header: 'Due date',
      accessor: 'due_date',
      id: 'due_date_column',
      Filter: column => filterText(column),
    },
    {
      Header: 'Comments count',
      accessor: 'comments_count',
      id: 'comments_count_column',
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.comments_count[0]} ({row.original.comments_count[1]}
            <Icon fill="#F0CF19" icon={iconStarSolid} description="Important" />
            )
          </span>
        );
      },
      Filter: column => filterText(column),
    },
    {
      Header: 'RB review',
      accessor: 'rb_review',
      id: 'rb_review_column',
      Cell: ({ row }) => {
        const {
          icons,
          counters: { checked_stack_count, total_stack_count },
        } = row.original.rb_review;
        return generateReviewIcons(
          icons,
          checked_stack_count,
          total_stack_count,
        );
      },
      Filter: column => filterText(column),
    },
    {
      Header: 'HQ review',
      accessor: 'hq_review',
      id: 'hq_review_column',
      Cell: ({ row }) => {
        const {
          icons,
          counters: { checked_stack_count, total_stack_count },
        } = row.original.hq_review;
        return generateReviewIcons(
          icons,
          checked_stack_count,
          total_stack_count,
        );
      },
      Filter: column => filterText(column),
    },
  ];

  return (
    <TableMain
      data={data}
      columns={columns}
      pagination="client"
      className="-striped -highlight"
      loading={loading}
      isFilterable
      initialState={{
        sortBy: [
          {
            id: 'project_country_column',
            desc: false,
          },
        ],
      }}
    />
  );
};

export default ReviewStatusTab;
