import { Button } from '@wfp/ui';
import React from 'react';
import { Helmet } from 'react-helmet';
import Select from 'react-select';

function AdministrationDashboardHeaderComponent(props) {
  const periods = props.periods ? props.periods : [];
  const options = periods.map(period => {
    return { value: period, label: period };
  });
  const className = 'administration-dashboard-header';

  return (
    <React.Fragment>
      <Helmet>
        <title>Administration Dashboard</title>
      </Helmet>

      <div className={className}>
        <h2 className={`${className}-title`}>Administration Dashboard</h2>
        <div className={`${className}-controls`}>
          <Button onClick={props.generateProjects} disabled={props.loading}>
            Generate selected projects
          </Button>
          <Button onClick={props.activateProjects} disabled={props.loading}>
            Activate selected projects
          </Button>
          <div className={`${className}-period-picker`}>
            <span className={`${className}-period-picker-title`}>Period</span>
            <Select
              className="admin-dashboard-react-select"
              classNamePrefix="wfp--react-select"
              name="projectTypeFilters"
              options={options}
              isSearchable={false}
              value={{ value: props.period, label: props.period }}
              id="adminDashboardPeriodsSelectId"
              key="adminDashboardPeriodsSelectId"
              onChange={e => {
                props.setPeriod(e.value);
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdministrationDashboardHeaderComponent;
