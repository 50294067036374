import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from 'src/App';
import store, { persistor } from 'src/redux/store';

import 'function.name-polyfill';
import 'src/assets/scss/app.scss';
import 'react-dates/initialize';
import '@wfp/ui/src/globals/scss/styles.scss';

// initialize Google Analytics
if (process.env.REACT_APP_GA_4) {
  ReactGA.initialize(
    [
      {
        trackingId: process.env.REACT_APP_GA_4,
      },
    ],
    { debug: true, alwaysSendToDefaultTracker: true },
  );

  ReactGA.send(
    'pageview',
    `${window.location.pathname}${window.location.search}${window.location.hash}`,
  );
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.ACR_ENV,
});

window.init_react = function () {
  window.djconfig = {};
  const root = ReactDOM.createRoot(document.getElementById('app-root'));
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
  );
};

window.init_react();
