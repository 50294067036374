import { Form, Modal, Icon } from '@wfp/ui';
import { iconSearch } from '@wfp/icons';

import React, { FC, useState } from 'react';
import { apiCallAsync } from 'src/redux/sagas';
import { getActionAPISource } from 'src/redux/actions';

import {
  IEditUserFormFields,
  IOptionStringValue,
  IOptionValue,
  IUserData,
} from 'src/types';
import { useForm } from 'react-hook-form';
import { showNotification } from 'src/utils';
import UserInfoBox from '../UserInfoBox';
import EditUserRepeatableFields from '../EditUserRepeatableFields';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

interface Props {
  roles: IOptionValue[];
  offices: IOptionValue[];
  onClose: () => void;
  open: boolean;
}

const AddUserModal: FC<Props> = ({
  roles: rolesList,
  offices: officesList,
  onClose,
  open,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [lastSearch, setLastSearch] = useState([]);

  const [userData, setUserData] = useState<Partial<IUserData>>({
    roles: [],
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IEditUserFormFields>({
    defaultValues: {
      roles: [
        {
          role: 0,
          office: [],
        },
      ],
    },
  });

  function handleUpdateBtn() {
    handleSubmit(saveUser)();
  }

  function saveUser(data: IEditUserFormFields) {
    setIsSaving(true);

    const url = getActionAPISource('USERS_MANAGEMENT_LIST');
    apiCallAsync(url, { user_data: { ...userData, roles: data.roles } }, 'post')
      .then(response => {
        if (response.status === 200) {
          setIsSaving(false);
          if ('error' in response.data) {
            showNotification(response.data.error, 'error');
          } else {
            showNotification(response.data.message, 'success');
            onClose();
          }
        }
      })
      .catch(e => {
        console.log(e);
        setIsSaving(false);
      });
  }

  function ifEmail(toCheck: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(String(toCheck).toLowerCase());
    return isValid;
  }

  function fillUserData(email: string) {
    if (ifEmail(email)) {
      let suggestUser: any = {};
      for (const each of lastSearch) {
        if (each.email === email) {
          suggestUser = each;
        }
      }
      if (!!suggestUser && Object.keys(suggestUser).length > 0) {
        setUserData({
          first_name: suggestUser.first_name,
          last_name: suggestUser.last_name,
          email: suggestUser.email,
          username: suggestUser.login_name,
          roles: [],
        });
      }
    } else {
      setUserData({
        email: null,
        roles: [],
      });
      setLastSearch([]);
    }
  }

  async function handleUsernameChange(val: string = '') {
    if (!!val && val.length >= 3) {
      return await suggestUsers(val);
    }
  }

  async function suggestUsers(partName: string) {
    const url = `${getActionAPISource(
      'USERS_MANAGEMENT_SUGGEST',
    )}?user_data=${partName}`;

    return await apiCallAsync(url, {}, 'get').then(response => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          setLastSearch(response.data);
          return response.data.map(row => ({
            label: row.email,
            value: row.email,
          }));
        }
      }
    });
  }

  function processEmail(e: IOptionStringValue) {
    if (e === null) return;

    const email = e.value.toLowerCase();
    fillUserData(email);
  }

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon description="Search" icon={iconSearch} />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <>
      <Modal
        open={open}
        onRequestSubmit={handleUpdateBtn}
        // @ts-ignore: Wrong type check in wfp UI Kit
        onSecondarySubmit={onClose}
        hideClose={true}
        modalHeading="Add User"
        primaryButtonText="Add User"
        secondaryButtonText="Cancel"
        primaryButtonDisabled={isSaving || !userData?.email}
        secondaryButtonDisabled={isSaving}
        iconDescription="Close the modal"
        className="edit-user-modal-class"
        id="add-user-modal"
        selectorPrimaryFocus={false}
        wide
      >
        <>
          <AsyncSelect
            isDisabled={!!userData?.email}
            loadOptions={handleUsernameChange}
            placeholder="Start typing first name or last name"
            className="wfp--react-select-container"
            classNamePrefix="wfp--react-select"
            hideSelectedOptions={false}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            id="add-user-autocomplete"
            onChange={(event: IOptionStringValue) => processEmail(event)}
            escapeClearsValue={false}
            isSearchable
            closeMenuOnSelect
            noOptionsMessage={val => {
              if (val?.inputValue.length >= 3) {
                return 'No matching users found';
              }
              return null;
            }}
            loadingMessage={() => null}
          />
          {userData.email && (
            <>
              <UserInfoBox userData={userData as IUserData} />
              <Form id="edit-user-form">
                <EditUserRepeatableFields
                  name="roles"
                  control={control}
                  errors={errors}
                  appendValue={{ role: 0, office: [] }}
                  rolesList={rolesList}
                  officesList={officesList.filter(({ value }) => value !== 0)}
                />
              </Form>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default AddUserModal;
