import React, { useState, useRef } from 'react';
import { Button } from '@wfp/ui';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';

import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from 'src/hooks';

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
  width: number,
  height: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width,
        height,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const ImageCropper = ({
  imgSrc,
  width,
  height,
  changeFinalImage,
  suggestedName,
  handleCancelCrop,
}) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [croppedFile, setCroppedFile] = useState(null);
  const [croppedSrc, setCroppedSrc] = useState('');

  const aspect = width / height;

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width: mediaWidth, height: mediaHeight } = e.currentTarget;

    setCrop(centerAspectCrop(mediaWidth, mediaHeight, aspect, width, height));
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0,
        );

        await setCroppedImg();
      }
    },
    100,
    [completedCrop],
  );

  function onCropSave() {
    changeFinalImage(croppedSrc, croppedFile);
  }

  async function setCroppedImg() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;

    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    const offscreen = new OffscreenCanvas(width, height);
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    const blob = await offscreen.convertToBlob({
      type: 'image/jpeg',
      quality: 0.95,
    });

    const croppedImage = new File([blob], `${suggestedName}.jpg`, {
      type: 'image/jpeg',
    });

    setCroppedFile(croppedImage);
    setCroppedSrc(URL.createObjectURL(blob));
  }

  async function makeClientCrop(crop) {
    setCompletedCrop(crop);
  }

  return (
    <div className="Cropper">
      <div>
        <p
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
          data-test-id="image-cropper-message"
        >
          Please select proper image area and hit &quot;Save&quot; to continue.
        </p>
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <Button
          kind="primary"
          onClick={onCropSave}
          data-test-id="cropper-save-image-button"
          disabled={!croppedFile && !croppedSrc}
        >
          Save image
        </Button>
        <Button
          kind="ghost"
          onClick={handleCancelCrop}
          data-test-id="cropper-cancel-button"
        >
          Cancel
        </Button>
      </div>
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => {
          setCrop(percentCrop);
        }}
        onComplete={c => makeClientCrop(c)}
        aspect={aspect}
        minHeight={height}
        locked
      >
        <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
      </ReactCrop>

      {!!completedCrop && (
        <>
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                display: 'none',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCropper;
