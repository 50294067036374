import React, { useRef, FC } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  content: string;
  onChange: (value: string) => void;
}

const TinyEditorWrapper: FC<Props> = ({ content, onChange }) => {
  const editorRef = useRef(null);

  const allowedAttributes = `id|accesskey|class|dir|lang|style|tabindex|title|role|contenteditable|contextmenu|draggable|dropzone|hidden|spellcheck|translate|xml:lang`;

  return (
    <>
      <Editor
        tinymceScriptSrc={`${process.env.REACT_APP_DOMAIN}/tinymce/tinymce.min.js`}
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onEditorChange={onChange}
        value={content}
        init={{
          height: 500,
          menubar: false,
          plugins: 'lists',
          branding: false,
          allow_html_in_named_anchor: true,
          toolbar1: 'cut copy paste | undo redo',
          toolbar2:
            'bold italic underline strikethrough superscript | removeformat | numlist bullist | indent outdent',
          browser_spellcheck: true,
          remove_trailing_brs: false,
          valid_elements: `-a[name|${allowedAttributes}],-strong/b[${allowedAttributes}],-em/i[${allowedAttributes}],-sub[${allowedAttributes}],-sup[${allowedAttributes}],-strike[${allowedAttributes}],-u[${allowedAttributes}],#p[${allowedAttributes}],-ul[${allowedAttributes}|type|compact],-ol[${allowedAttributes}|reversed|start|type|compact],#li[${allowedAttributes}|value|type],-s[${allowedAttributes}],br[${allowedAttributes}|clear],-span[${allowedAttributes}]`,
        }}
      />
    </>
  );
};

export default TinyEditorWrapper;
