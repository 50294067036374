import {
  iconCheckmarkOutline,
  iconCloseOutline,
  iconSettingsGlyph,
} from '@wfp/icons';
import { Button, ContentSwitcher, Icon, Modal, Switch, Toggle } from '@wfp/ui';
import { DynamicIcon } from 'acr_ui/sources/components/DynamicIcon';
import clone from 'lodash/clone';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { connect } from 'react-redux';

import { ICON_KWARGS } from 'src/constants';
import { requestAPI } from 'src/redux/actions';
import { SECTION_DETAIL_DATA_KEY } from 'src/redux/report/constants';

export const ASSET_STATUSES_URL = 'TECHNICAL_REVIEW_ASSET_STATUSES_URL';
export const ASSETS_URL = 'TECHNICAL_REVIEW_ASSETS_URL';

export const ASSETS = 'assets';
export const ASSET_STATUSES = 'assetStatuses';

export class TechnicalReviewComponent extends React.Component {
  state = {
    isActive: false,
    activeGroup: null,
    toggleIconStatuses: {},
    toggleIconVisibility: {},
    editReviewsMode: false,
  };

  constructor(props, context) {
    super(props, context);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => {
    this.setState(() => ({ isActive: true }));
  };

  handleClose() {
    this.setState(() => ({
      isActive: false,
      editReviewsMode: false,
      toggleIconVisibility: {},
    }));
  }

  handleStartEditIcons = () => {
    this.setState(() => ({ editReviewsMode: true }));
  };

  serializeReviewsGroupsData = () => {
    const serialisedData = clone(this.props[ASSETS].data);

    for (const groupName in this.state.toggleIconVisibility) {
      const changedGroupValues = this.state.toggleIconVisibility[groupName];
      for (const reviewId in changedGroupValues) {
        const review = serialisedData.reviews[reviewId],
          value = changedGroupValues[reviewId];
        if (value) {
          review.groups[groupName] = review.groups[groupName] || null;
        } else {
          delete review.groups[groupName];
        }
      }
    }
    return serialisedData;
  };

  handleSubmitEditedIcons = () => {
    this.setState(() => ({ editReviewsMode: false }));
    this.props.dispatchAssetsChange(this.serializeReviewsGroupsData());
  };
  handleCancelEditIcons = () => {
    this.setState(() => ({
      editReviewsMode: false,
      toggleIconVisibility: {},
    }));
  };

  handleReviewStatusToggle = event => {
    event.stopPropagation();
    const review = event.currentTarget.getAttribute('data-review'),
      action = event.currentTarget.getAttribute('data-action');

    const val = `review=${review}&action=${action}`;
    if (!review || !action) {
      console.error(`Data is missing review=${review}; action=${action};`);
      return;
    }
    this.props.dispatchAssetStatusChange(val);
  };

  handleIconVisibilityToggle = (value, toggleId) => {
    if (this.state.activeGroup) {
      this.setState(state => {
        const newState = {
          toggleIconVisibility: {
            [this.state.activeGroup]: {},
            ...state.toggleIconVisibility,
          },
        };
        newState.toggleIconVisibility[this.state.activeGroup][toggleId] = value;
        return newState;
      });
    } else {
      console.error(
        `Active group is not selected. Can't set value for ${toggleId}`,
      );
    }
  };

  handleIconVisibilityToggleAll = value => {
    if (this.state.activeGroup) {
      this.setState(state => {
        const newState = {
          toggleIconVisibility: {
            [this.state.activeGroup]: {},
            ...state.toggleIconVisibility,
          },
        };
        for (const reviewId in this.props[ASSETS].data.reviews) {
          newState.toggleIconVisibility[this.state.activeGroup][reviewId] =
            value;
        }
        return newState;
      });
    } else {
      console.error(
        `Active group is not selected. Can't perform selection/deselection.`,
      );
    }
  };

  componentDidMount() {
    this.fetchDataIfNeeded();
  }

  componentDidUpdate() {
    this.fetchDataIfNeeded();
    if (!this.state.activeGroup && this.isDataLoaded(ASSETS)) {
      this.setInitialGroupTab();
    }
  }

  setInitialGroupTab = () => {
    const groupKeys = this.props[ASSETS].data.groups.map(group => group.name);
    if (groupKeys.length) {
      this.setState({ activeGroup: groupKeys[0] });
    }
  };

  isDataLoaded = (dataKey, props) => {
    props = props === undefined ? this.props : props;
    return props[dataKey].data !== null;
  };

  isFetchingOfDataNeeded = (dataKey, props) => {
    props = props === undefined ? this.props : props;
    return (
      !!this.state.isActive &&
      !!this.props.reportPk &&
      !!this.props.officeCode &&
      !props[dataKey].isFetching &&
      props[dataKey].error === null &&
      !this.isDataLoaded(dataKey, props)
    );
  };

  fetchDataIfNeeded = () => {
    for (const key of [ASSET_STATUSES, ASSETS]) {
      if (this.isFetchingOfDataNeeded(key)) {
        this.props.dispatchFetch[key](this.props.reportPk);
      }
    }
  };

  setActiveGroup = ({ name }) => {
    this.setState({ activeGroup: name });
  };

  renderNav = assetsData => {
    const groupKeys = assetsData.groups.map(group => group.name),
      activeGroupIndex = groupKeys.indexOf(this.state.activeGroup);

    return (
      <ContentSwitcher
        className="group-nav"
        selectedIndex={activeGroupIndex !== -1 ? activeGroupIndex : 0}
        onChange={this.setActiveGroup}
      >
        {groupKeys.map((groupName, index) => {
          return <Switch key={index} name={groupName} text={groupName} />;
        })}
      </ContentSwitcher>
    );
  };

  makeReviewStatusFunc = (reviewStack, reviewStatus, showToggle) => {
    let reviewStatusToggle = null;
    if (showToggle) {
      const review = `${reviewStack}`;

      const iconKwargs = {
          height: '1.2em',
        },
        buttonKwargs = {
          onClick: this.handleReviewStatusToggle,
          'data-review': review,
        };
      reviewStatusToggle = (
        <footer className="review-status-change">
          <button {...buttonKwargs} data-action={0} className="enable-review">
            <Icon
              icon={iconCheckmarkOutline}
              fill="#0a6eb4"
              description=""
              {...iconKwargs}
            />
          </button>
          <button {...buttonKwargs} data-action={1} className="disable-review">
            <Icon
              icon={iconCloseOutline}
              fill="#c5192d"
              description=""
              {...iconKwargs}
            />
          </button>
        </footer>
      );
    }
    return (
      (!!reviewStatus || reviewStatusToggle) && (
        <div className="review-status">
          {reviewStatus && (
            <>
              <span className="user">{reviewStatus.user}</span>
              <span className="date">{reviewStatus.date}</span>
            </>
          )}
          {reviewStatusToggle}
        </div>
      )
    );
  };

  renderAssets = assetsData => {
    const sortedReviews = assetsData?.reviews
      ? sortBy(assetsData.reviews, 'name')
      : [];

    const groupKeys = assetsData.groups.map(group => group.name),
      activeGroupIndex = groupKeys.indexOf(this.state.activeGroup),
      activeGroupName =
        activeGroupIndex !== -1
          ? assetsData.groups[activeGroupIndex].name
          : groupKeys.length > 0
          ? assetsData.groups[0].name
          : null;

    const listClasses = ['reviews-list'];

    if (activeGroupName) {
      if (this.state.editReviewsMode) {
        listClasses.push('being-edited');
        const selectedGroup = [],
          deselectedGroup = [];
        //sort assets by alphabetical order
        const assetsDataArray = sortBy(Object.entries(assetsData.reviews), [
          function (o) {
            return o[1].name;
          },
        ]);
        const idsInOrder = assetsDataArray.map(item => {
          return item[0];
        });

        for (const reviewId in idsInOrder) {
          const review = assetsData.reviews[idsInOrder[reviewId]],
            initiallySelected =
              typeof review.groups[activeGroupName] !== 'undefined',
            currentContainer = initiallySelected
              ? selectedGroup
              : deselectedGroup;

          const toggled = (
            this.state.toggleIconVisibility[this.state.activeGroup] || {}
          ).hasOwnProperty(idsInOrder[reviewId])
            ? this.state.toggleIconVisibility[this.state.activeGroup][
                idsInOrder[reviewId]
              ]
            : typeof review.groups[activeGroupName] !== 'undefined';
          const itemClasses = ['review-item'];

          currentContainer.push(
            <div className={itemClasses.join(' ')} key={idsInOrder[reviewId]}>
              <figure>
                <DynamicIcon
                  url={review.icon_url}
                  width={32}
                  height={32}
                  customClass={`review-icon-aws ${
                    review.icon === 'icon-sdg' ? 'icon-sdg ' : ''
                  }`}
                  disableAutoFillPaths={[
                    'icon-sdg',
                    'icon-environment',
                  ].includes(review.icon)}
                />
                <figcaption>{review.name}</figcaption>
              </figure>
              <div className="icon-visibility-control">
                <Toggle
                  id={`${idsInOrder[reviewId]}`}
                  labelB=""
                  labelA=""
                  toggled={toggled}
                  onToggle={this.handleIconVisibilityToggle}
                />
              </div>
            </div>,
          );
        }
        return (
          <>
            <h5 className="group-title">Was selected:</h5>
            <div className={['selected', ...listClasses].join(' ')}>
              {selectedGroup}
            </div>
            <h5 className="group-title">Wasn&apos;t selected:</h5>
            <div className={['deselected', ...listClasses].join(' ')}>
              {deselectedGroup}
            </div>
          </>
        );
      } else {
        const activeGroupComponents = [];
        const reviewStatusesData = this.props[ASSET_STATUSES].data
            ? this.props[ASSET_STATUSES].data.actions
            : null,
          showToggle =
            !!reviewStatusesData &&
            !!this.props.hasPermissionToMark &&
            this.props.sectionData?.data?.permissions?.can_change_reviewaction;

        for (const reviewId in sortedReviews) {
          const review = sortedReviews[reviewId],
            reviewStack = review.groups[activeGroupName];

          if (typeof reviewStack === 'undefined') continue;

          const reviewStatus = reviewStatusesData
            ? reviewStatusesData[reviewStack]
            : null;
          activeGroupComponents.push(
            <div
              className={`review-item${reviewStatus ? ' checked' : ''}`}
              key={reviewId}
            >
              <figure>
                <DynamicIcon
                  url={review.icon_url}
                  width={32}
                  height={32}
                  disableAutoFillPaths={[
                    'icon-sdg',
                    'icon-environment',
                  ].includes(review.icon)}
                  customClass={`review-icon-aws ${
                    review.icon === 'icon-sdg' ? 'icon-sdg ' : ''
                  } ${reviewStatus ? 'review-status' : ''}`}
                />
                <figcaption>{review.name}</figcaption>
              </figure>
              {reviewStatusesData &&
                this.makeReviewStatusFunc(
                  reviewStack,
                  reviewStatus,
                  showToggle,
                )}
            </div>,
          );
        }
        return (
          <div className={listClasses.join(' ')}>{activeGroupComponents}</div>
        );
      }
    }
  };

  renderFooterContent = () => {
    return this.state.editReviewsMode ? (
      <>
        <Button
          onClick={this.handleSubmitEditedIcons}
          data-test-id="save-button"
        >
          Save
        </Button>
        <Button
          onClick={this.handleCancelEditIcons}
          data-test-id="cancel-button"
        >
          Cancel
        </Button>
        <Button
          className="float-right"
          onClick={this.handleIconVisibilityToggleAll.bind(this, true)}
          data-test-id="select-all-button"
        >
          Select All
        </Button>
        <Button
          className="float-right"
          onClick={this.handleIconVisibilityToggleAll.bind(this, false)}
          data-test-id="deselect-all-button"
        >
          Deselect all
        </Button>
      </>
    ) : (
      <Button
        onClick={this.handleStartEditIcons}
        data-test-id="add-remove-icons-button"
      >
        Add/Remove icons
      </Button>
    );
  };

  renderModalContent = () => {
    const assets = this.props[ASSETS].data;

    return (
      <>
        <header>
          {assets ? this.renderNav(assets) : null}
          {this.state.editReviewsMode ? (
            <strong>Add/Remove icons</strong>
          ) : (
            <strong>Review by function</strong>
          )}
        </header>

        <div className="tr-modal-content">
          {assets ? this.renderAssets(assets) : null}
        </div>
        {this.props.hasPermissionToMark &&
          this.props.sectionData?.data?.permissions?.can_change_reviewasset && (
            <footer>{this.renderFooterContent()}</footer>
          )}
      </>
    );
  };

  render() {
    return (
      <>
        <Button
          onClick={this.handleOpen}
          className="wfp--btn--secondary header-btn"
          data-test-id="review-by-function-button"
        >
          Review by function
          <Icon icon={iconSettingsGlyph} {...ICON_KWARGS} />
        </Button>
        <Modal
          open={this.state.isActive}
          className={`technical-review-modal ${
            this.state.editReviewsMode ? 'edit-mode' : ''
          }`}
          passiveModal
          onRequestClose={this.handleClose}
        >
          {this.renderModalContent()}
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = state => {
  const newState = {
    [ASSET_STATUSES]: {
      data: null,
      isFetching: false,
      error: null,
      ...state.viewData[ASSET_STATUSES_URL],
    },
    [ASSETS]: {
      data: null,
      isFetching: false,
      error: null,
      ...state.viewData[ASSETS_URL],
    },
    sectionData: { ...state.viewData?.[SECTION_DETAIL_DATA_KEY] },
  };
  return newState;
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchFetch: {
      [ASSET_STATUSES]: () => {
        dispatch(
          requestAPI(ASSET_STATUSES_URL, null, {
            url_format: {
              _report_: ownProps.reportPk,
              _office_: ownProps.officeCode,
            },
          }),
        );
      },
      [ASSETS]: () => {
        dispatch(
          requestAPI(ASSETS_URL, null, {
            url_format: {
              _report_: ownProps.reportPk,
              _office_: ownProps.officeCode,
            },
          }),
        );
      },
    },
    dispatchAssetStatusChange: data => {
      dispatch(
        requestAPI(ASSET_STATUSES_URL, null, {
          url_format: {
            _report_: ownProps.reportPk,
            _office_: ownProps.officeCode,
          },
          data: data,
          method: 'post',
        }),
      );
    },
    dispatchAssetsChange: data => {
      dispatch(
        requestAPI(ASSETS_URL, null, {
          url_format: {
            _report_: ownProps.reportPk,
            _office_: ownProps.officeCode,
          },
          data: data,
          method: 'post',
        }),
      );
    },
  };
};

export const TechnicalReview = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TechnicalReviewComponent);
