import { iconCheckmark } from '@wfp/icons';
import { Icon, tooltipStyle } from '@wfp/ui';
import { Checkbox, Tab, Tabs } from '@wfp/ui';
import { Button } from '@wfp/ui';
import { DynamicIcon } from 'acr_ui/sources/components/DynamicIcon';
import { Tooltip } from 'acr_ui/sources/components/Tooltip';
import { isEmpty } from 'lodash';
import React, {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { connect, useDispatch, useSelector } from 'react-redux';
import { generatePath, NavLink } from 'react-router-dom';
import Select from 'react-select';

import { NoAccess } from 'src/components/BaseLayout';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { DATE_FORMAT } from 'src/constants';
import { getActionAPISource } from 'src/redux/actions';
import {
  clearApiKeys,
  getPublicationListRequest,
  requestAPI,
  resetReportsList,
} from 'src/redux/actions';
import { changeDatePicker } from 'src/redux/report/actions';
import {
  GET_ALL_REPORTS_PERIODS,
  GET_APP_CONFIGURATION,
  PDF_MERGE_MAKE_MERGE,
  PDF_MERGE_MERGE_STATUS,
  PDF_MERGE_PERIODS,
  REPORT_LIST_DATA_KEY,
} from 'src/redux/report/constants';
import { apiCallAsync } from 'src/redux/sagas';
import {
  buildErrorComponents,
  calcMergeStatus,
  getLocalStorageItem,
  setLocalStorageItem,
  showNotification,
} from 'src/utils';
import ACRMultiFilesUploadButton from './ACRMultiFilesUploadButton';
import ExportTablesButton from './ExportTablesButton';
import HeaderExportButton from './HeaderExportButton';
import ProjectStatusTab from './ProjectStatusTab';
import PublicationStatusTab from './PublicationStatusTab';
import ReviewStatusTab from './ReviewStatusTab';

export const PROJECT_STATUS = 'project';
export const REVIEW_STATUS = 'review';
export const PUBLICATION_STATUS = 'publication';

const dashboardTypes = {
  [PROJECT_STATUS]: 'Project Status',
  [REVIEW_STATUS]: 'Review Status',
  [PUBLICATION_STATUS]: 'Publication Status',
};

const parametersList = [
  'category',
  'project_country',
  'project_regional_bureau_display_code',
  'project_code',
  'project_type',
  'current_status',
  'initial_draft',
  'review',
  'cd_approval',
  'rd_approval',
];

const headers = [
  { key: 'project_code', title: 'NUMBER' },
  { key: 'category', title: 'CATEGORY' },
  { key: 'project_regional_bureau_display_code', title: 'REGIONAL BUREAU' },
  { key: 'project_country', title: 'COUNTRY' },
  { key: 'project_type', title: 'PROJECT TYPE' },
  { key: 'current_status', title: 'CURRENT STATUS' },
  { key: 'initial_draft', title: 'INITIAL DRAFT' },
  { key: 'review', title: 'REVIEW' },
  { key: 'cd_approval', title: 'CD APPROVED' },
  { key: 'rd_approval', title: 'RD APPROVAL' },
  { key: 'rd_approved', title: 'RD APPROVED' },
  { key: 'published', title: 'PUBLISHED' },
];

export class ReportsListContainer extends Component {
  constructor(props) {
    super(props);

    this.handlePeriodOnChange = this.handlePeriodOnChange.bind(this);
    this.changeListStatus = this.changeListStatus.bind(this);
    this.buildParametersList = this.buildParametersList.bind(this);
    this.buildHeaders = this.buildHeaders.bind(this);
    this.state = {
      publicationStatusYears: [],
      listStatus: this.props.location.hash
        ? this.props.location.hash.slice(1)
        : PROJECT_STATUS,
    };
  }

  async componentDidMount() {
    this.props.fetchAppConf();

    if (this.state.listStatus !== 'publication') {
      this.fetchDataIfNeeded();
      this.enforceExistingPeriod();
    } else {
      const publicationStatusYears = await this.getPublicationReportsYears();
      this.setState({ publicationStatusYears });
      this.props.getPublicationList(
        publicationStatusYears.indexOf(this.props.match.params.year) === -1
          ? undefined
          : this.props.match.params.year,
      );
      this.enforceExistingPeriod(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props[REPORT_LIST_DATA_KEY]?.error !==
        prevProps[REPORT_LIST_DATA_KEY]?.error &&
      this.props[REPORT_LIST_DATA_KEY]?.error !== null &&
      this.props[REPORT_LIST_DATA_KEY]?.error !== undefined
    ) {
      const errorsList = buildErrorComponents(
        this.props[REPORT_LIST_DATA_KEY].error,
      );
      showNotification(errorsList, 'error', {
        autoClose: false,
        closeOnClick: false,
      });
    }

    if (prevProps.location.hash !== this.props.location.hash) {
      const listStatus = this.props.location.hash
        ? this.props.location.hash.slice(1)
        : PROJECT_STATUS;
      this.changeListStatus(listStatus);
    }

    if (this.state.listStatus !== 'publication') {
      if (this.props.match.params.year !== prevProps.match.params.year) {
        this.props.flushReportsListData();
      }
      this.fetchDataIfNeeded();
      this.enforceExistingPeriod();
    } else {
      if (this.props.match.params.year !== prevProps.match.params.year) {
        this.props.getPublicationList(this.props.match.params.year);
      }

      this.enforceExistingPeriod(true);
    }
  }

  async enforceExistingPeriod(isPublication = false) {
    const periods = isPublication
      ? this.state.publicationStatusYears
      : this.props[GET_ALL_REPORTS_PERIODS].data;
    const yearParam = this.props.match.params.year;

    if (!!periods && periods.length > 0 && periods.indexOf(yearParam) === -1) {
      this.props.history.push({
        pathname: generatePath(this.props.match.path, { year: periods[0] }),
        hash: this.props.location.hash
          ? this.props.location.hash.slice(1)
          : PROJECT_STATUS,
      });
    }
  }

  getPublicationReportsYears = async () => {
    try {
      const url = getActionAPISource(PDF_MERGE_PERIODS);
      const response = await apiCallAsync(url, null, 'get');
      return response?.data;
    } catch (error) {
      console.error(error);
    }
  };

  isDataLoaded = (dataKey, props) => {
    props = props === undefined ? this.props : props;
    return props[dataKey].data !== null;
  };

  isFetchingOfDataNeeded = (dataKey, props) => {
    props = props === undefined ? this.props : props;
    /* Don't upload reports till we are on the route that matches an
     available period */
    const periods = props[GET_ALL_REPORTS_PERIODS].data || [];
    const noPeriod = periods.indexOf(props.match.params.year) === -1;

    if (dataKey === REPORT_LIST_DATA_KEY && noPeriod) return false;

    return (
      !props[dataKey].isFetching &&
      props[dataKey].error === null &&
      !this.isDataLoaded(dataKey, props)
    );
  };

  fetchDataIfNeeded = () => {
    // Note: the order is meaningful! Get available periods first
    for (const key of [GET_ALL_REPORTS_PERIODS, REPORT_LIST_DATA_KEY]) {
      if (this.isFetchingOfDataNeeded(key)) {
        this.props.dispatchFetch[key](this.state.listStatus);
        // Don't upload `REPORT_LIST_DATA_KEY` till you know available periods
        if (key === GET_ALL_REPORTS_PERIODS) break;
      }
    }
  };

  handlePeriodOnChange(option) {
    this.props.history.push({
      pathname: generatePath(this.props.match.path, { year: option.value }),
      hash: this.props.location.hash
        ? this.props.location.hash.slice(1)
        : PROJECT_STATUS,
    });
  }

  async changeListStatus(key) {
    if (key === this.state.listStatus) return;

    this.setState({ listStatus: key });

    if (key === 'publication') {
      const publicationStatusYears = await this.getPublicationReportsYears();
      this.setState({ publicationStatusYears });
      this.props.getPublicationList(
        publicationStatusYears.indexOf(this.props.match.params.year) === -1
          ? undefined
          : this.props.match.params.year,
      );
    } else {
      this.props.dispatchFetch[REPORT_LIST_DATA_KEY](key);
    }
  }

  buildParametersList() {
    // copy array by value
    const currentParametersList = parametersList.slice();

    // check status
    if (this.state.listStatus === PROJECT_STATUS) {
      // splice by current_status parameters lists index
      const currentStatusIndex = currentParametersList.findIndex(
        x => x === 'current_status',
      );
      currentParametersList.splice(
        currentStatusIndex,
        currentParametersList.length,
      );
      currentParametersList.push(...['review_status', 'due_date']);
    }
    return currentParametersList;
  }

  buildHeaders() {
    // copy array by value
    const currentHeaders = headers.slice();

    if (this.state.listStatus === PROJECT_STATUS) {
      // splice by current_status header's index
      const currentStatusIndex = currentHeaders.findIndex(
        x => x.key === 'current_status',
      );
      currentHeaders.splice(currentStatusIndex, currentHeaders.length);
      currentHeaders.push(
        ...[
          { key: 'review_status', title: 'Review status' },
          { key: 'due_date', title: 'Due date' },
          { key: 'comments_count', title: 'Comments Count' },
          { key: 'rb_review', title: 'RB review' },
          { key: 'hq_review', title: 'HQ review' },
        ],
      );
    }
    return currentHeaders;
  }

  render() {
    const { isFetching, data, error } = this.props[REPORT_LIST_DATA_KEY];
    const periods = this.props[GET_ALL_REPORTS_PERIODS].data || [];
    const paramYear = this.props.match.params.year;
    const cPeriod = periods.indexOf(paramYear) === -1 ? undefined : paramYear;

    return (
      <React.Fragment>
        <Helmet>
          <title>{dashboardTypes[this.state.listStatus]}</title>
        </Helmet>
        <ReportsListPresentation
          location={this.props.location}
          isFetching={isFetching}
          error={error}
          data={data || []}
          listStatus={this.state.listStatus}
          changeListStatus={this.changeListStatus}
          handlePeriodOnChange={this.handlePeriodOnChange}
          periods={
            this.state.listStatus === 'publication'
              ? this.state.publicationStatusYears.map(i => ({
                  value: i,
                  label: i,
                }))
              : periods.map(i => ({ value: i, label: i }))
          }
          currentPeriod={
            this.state.listStatus === 'publication'
              ? this.state.publicationStatusYears.indexOf(paramYear) === -1
                ? undefined
                : paramYear
              : cPeriod
          }
        />
      </React.Fragment>
    );
  }
}

export function TabNavLink({ href, label }) {
  return (
    <NavLink
      to={{ hash: href }}
      data-test-id={`${label.toLowerCase().replace(' ', '-')}-link`}
    >
      {label}
    </NavLink>
  );
}

function filterText({ column: { setFilter } }) {
  return (
    <input
      type="text"
      onChange={event => setFilter(event.currentTarget.value)}
      style={{ width: '100%' }}
      data-test-id="operation-id-search"
    />
  );
}

export function ReportsListPresentation(props) {
  const dispatch = useDispatch();

  const appConfig = useSelector(
    state => state.viewData?.GET_APP_CONFIGURATION?.data,
  );
  const roles = appConfig?.userInfo?.roles;
  const isHqAdmin = roles && roles.includes('hq_admin');

  const dashboardKeysList = useMemo(() => {
    const list = [PROJECT_STATUS, REVIEW_STATUS];
    if (isHqAdmin) list.push(PUBLICATION_STATUS);

    return list;
  }, [appConfig, isHqAdmin]);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [readyProjectIds, setReadyProjectIds] = useState([]);

  const [checkMergeStatus, setCheckMergeStatus] = useState(false);

  const toggleReadyProjectId = useCallback(
    projectId => {
      const projectIdKey = readyProjectIds.indexOf(projectId);

      const readyProjectsIdsClone = [...readyProjectIds];

      if (projectIdKey === -1) {
        readyProjectsIdsClone.push(projectId);
      } else {
        readyProjectsIdsClone.splice(projectIdKey, 1);
      }

      setReadyProjectIds(readyProjectsIdsClone);
    },
    [readyProjectIds, setReadyProjectIds],
  );

  const publicationData = useMemo(() => {
    return props.listStatus !== 'publication'
      ? []
      : props.data?.map(reportRow => {
          const mergeStatus = calcMergeStatus(reportRow);
          const { current_status } = reportRow;

          const currentStatus = headers
            .find(item => item.key === current_status)
            ?.title.toLowerCase();

          return {
            ...reportRow,
            current_status: currentStatus,
            merge_status: mergeStatus,
          };
        });
  }, [props.data, props.listStatus]);

  const mergeReadyFiles = useMemo(() => {
    return publicationData
      .filter(({ merge_status }) => merge_status === 'Ready')
      .map(item => item.project_code);
  }, [publicationData]);

  useEffect(() => {
    const mergePending = getLocalStorageItem('waiting_merge_result');
    if (mergePending) setCheckMergeStatus(true);
  }, []);

  useEffect(() => {
    const timeoutID = checkMergeStatus
      ? setInterval(() => {
          handleRefreshMergeStatusCheck();
        }, 3000)
      : null;
    if (!checkMergeStatus && timeoutID) {
      clearInterval(timeoutID);
    }

    return () => {
      timeoutID && clearInterval(timeoutID);
    };
  }, [checkMergeStatus]);

  async function handleRefreshMergeStatusCheck() {
    const url = getActionAPISource(PDF_MERGE_MERGE_STATUS);

    try {
      const response = await apiCallAsync(url, null, 'get');
      const { processing, errors } = response?.data;
      if (processing) return;

      if (isEmpty(errors)) {
        showNotification('Merge completed', 'success');
        dispatch(resetReportsList());
        dispatch(
          getPublicationListRequest({
            period: props.currentPeriod
              ? props.currentPeriod
              : props.location.pathname.replace(/[^0-9]/g, ''),
          }),
        );
      } else {
        showNotification(<MergeErrorsMsg errors={errors} />, 'error');
      }
      setCheckMergeStatus(false);
      setLocalStorageItem('waiting_merge_result', '-');
    } catch (error) {
      console.log(error);
    }
  }

  const MergeErrorsMsg = ({ errors }) => {
    return errors.map(error => {
      const objKey = Object.keys(error);
      const reportInfo = publicationData.find(
        item => item.pk === parseInt(objKey?.[0]),
      );

      return (
        <div key={objKey?.[0]}>
          <li>
            {reportInfo?.project_code}: {error[parseInt(objKey?.[0])]}
          </li>
          <br />
        </div>
      );
    });
  };

  function handleSetSelectFilter(event, setFilter, isEqual = false) {
    let selectedOptions = [];

    if (event.target.value === 'all') {
      if (isEqual) {
        selectedOptions = null;
      }
    } else {
      selectedOptions = [event.target.value];
    }

    setFilter(selectedOptions);
  }

  function filterSelect(id, { column: { filterValue, setFilter } }, isEqual) {
    const options = getOptions(id);

    return (
      <select
        style={{ width: '100%' }}
        value={filterValue ? filterValue[0] : null}
        onChange={event => {
          handleSetSelectFilter(event, setFilter, isEqual);
        }}
      >
        {options}
      </select>
    );
  }

  function handleDateChange(date, state, reportPk) {
    if (date && date?.isValid()) {
      dispatch(
        changeDatePicker(
          date?.format(DATE_FORMAT),
          state,
          reportPk,
          props.currentPeriod,
        ),
      );
    }
  }

  function handleDateReset(state, reportPk) {
    dispatch(changeDatePicker(null, state, reportPk, props.currentPeriod));
  }

  function generateReviewIcons(icons, checked_stack_count, total_stack_count) {
    const iconSet = icons.map((icon, index) => {
      return (
        <figure className="rb-review-icon" key={`${icon}-${index}`}>
          <DynamicIcon
            url={icon.icon_url}
            width={18}
            height={18}
            disableAutoFillPaths={['icon-sdg', 'icon-environment'].includes(
              icon.icon,
            )}
            customClass={`review-icon-aws ${
              icon.icon === 'icon-sdg' ? 'icon-sdg ' : ''
            } ${icon.checked ? 'review-status' : ''}`}
          />
          <figcaption>{icon.title}</figcaption>
          {icon.checked && (
            <Icon
              className="review-checked"
              fill="#000"
              icon={iconCheckmark}
              description="Checked"
            />
          )}
        </figure>
      );
    });

    const reviewCount = `${checked_stack_count} out of ${total_stack_count}`;

    return (
      <>
        <span className="review-icon-tooltip-cell">
          {total_stack_count > 0 ? (
            <Tooltip
              position="left"
              title={<div className="review-icon-tooltip">{iconSet}</div>}
              tooltipStyle={tooltipStyle}
              theme="light review-icon-tooltip-wrapper"
              trigger="click"
            >
              {reviewCount}
            </Tooltip>
          ) : (
            reviewCount
          )}
        </span>
      </>
    );
  }

  async function mergeFiles() {
    const url = getActionAPISource(PDF_MERGE_MAKE_MERGE);

    const pkList = publicationData
      .filter(item => readyProjectIds.includes(item.project_code))
      .map(item => item.pk);

    try {
      const response = await apiCallAsync(url, { pk_list: pkList }, 'post');

      if (response?.data !== 'Merge started') throw Error;

      showNotification('Merge started', 'success');
      setLocalStorageItem('waiting_merge_result', '+');
      setCheckMergeStatus(true);
    } catch (error) {
      console.error(error);
      showNotification('Error while starting merge. Please try later', 'error');
    }

    setConfirmationModal(false);
  }

  async function handleMergeReady() {
    const url = getActionAPISource(PDF_MERGE_MERGE_STATUS);

    try {
      const response = await apiCallAsync(url, null, 'get');
      const { processing } = response?.data;

      if (processing) {
        showNotification('Another merge is in progress', 'error');
        return;
      }

      setConfirmationModal(true);
    } catch (error) {
      console.error(error);
      showNotification(
        'Error on checking merge status. Please try later',
        'error',
      );
    }
  }

  const getMergeButton = ({ disabled = false }) => {
    return (
      <Button
        disabled={disabled}
        onClick={handleMergeReady}
        data-test-id="merge-ready-button"
      >
        Merge ready
      </Button>
    );
  };

  function getOptions(key) {
    let data = props.data;
    if (props.listStatus === 'publication') {
      data = publicationData;
    }

    let value_list = data
      .filter(reportData => reportData[key])
      .map(reportData => reportData[key])
      .sort();

    value_list = [...new Set(value_list)];

    if (!value_list) return [];

    const options = value_list.map((value, index) => {
      return (
        <option key={index + 1} value={value}>
          {value}
        </option>
      );
    });

    const defaultOption = (
      <option key={0} value="all">
        -/-
      </option>
    );

    return [defaultOption, ...options];
  }

  const hash = props.location.hash
    ? props.location.hash.slice(1)
    : PROJECT_STATUS;

  const selectedValue = props.currentPeriod
    ? { value: props.currentPeriod, label: props.currentPeriod }
    : null;
  const selectedIndex = Object.keys(dashboardTypes).findIndex(
    item => item === hash,
  );

  if (props.listStatus === 'publication' && roles && !isHqAdmin) {
    return <NoAccess />;
  }

  return (
    <>
      <aside className="main-side-padding">
        <Tabs selected={selectedIndex} triggerHref="#" role="navigation">
          {dashboardKeysList.map(key => (
            <Tab
              renderAnchor={TabNavLink}
              href={key}
              label={dashboardTypes[key]}
              key={key}
              onClick={() => props.changeListStatus(key)}
              disabled={props.isFetching}
            />
          ))}
        </Tabs>
      </aside>

      <aside className="acr-dashboard-management-controls main-side-padding">
        <div className="acr-dashboard-period-picker-container">
          <div className="acr-dashboard-period-picker">
            <label
              className="acr-dashboard-period-picker-label"
              data-test-id="period-label"
            >
              Period
            </label>
            <Select
              classNamePrefix="wfp--react-select"
              name="projectTypeFilters"
              isSearchable={false}
              options={props.periods}
              value={selectedValue}
              id="periodsSelectId"
              onChange={props.handlePeriodOnChange}
              isDisabled={props.isFetching}
            />
          </div>
        </div>

        <div className="pull-right">
          <div className="projects-list-btns" style={{ display: 'flex' }}>
            {props.listStatus === 'publication' ? (
              <>
                <ACRMultiFilesUploadButton period={props.currentPeriod} />
                {mergeReadyFiles.length === 0 || checkMergeStatus ? (
                  <Tooltip
                    title="No records available"
                    trigger="mouseenter"
                    {...tooltipStyle}
                    theme="light"
                    position="bottom"
                    disabled={mergeReadyFiles.length > 0}
                  >
                    {getMergeButton({ disabled: true })}
                  </Tooltip>
                ) : (
                  <div>{getMergeButton({ disabled: false })}</div>
                )}
              </>
            ) : (
              <>
                <HeaderExportButton />
                <ExportTablesButton />
              </>
            )}
          </div>
        </div>
      </aside>

      <div className="reports-list">
        {props.listStatus === 'project' && (
          <ProjectStatusTab
            data={props.data}
            loading={props.isFetching}
            filterText={filterText}
            filterSelect={filterSelect}
            handleDateChange={handleDateChange}
            handleDateReset={handleDateReset}
          />
        )}

        {props.listStatus === 'review' && (
          <ReviewStatusTab
            data={props.data}
            loading={props.isFetching}
            filterText={filterText}
            filterSelect={filterSelect}
            generateReviewIcons={generateReviewIcons}
          />
        )}

        {props.listStatus === 'publication' && (
          <PublicationStatusTab
            data={publicationData}
            period={props.currentPeriod}
            filterText={filterText}
            filterSelect={filterSelect}
          />
        )}
      </div>
      <ConfirmationModal
        open={confirmationModal}
        onSubmit={mergeFiles}
        onClose={() => setConfirmationModal(false)}
        primaryButtonDisabled={!readyProjectIds?.length > 0}
        primaryButtonText="Confirm"
        content={
          <div className="merge-ready-modal">
            These ACR previews will be generated: <br />
            <br />
            {mergeReadyFiles.map(item => (
              <Checkbox
                id={item}
                key={item}
                labelText={item}
                onChange={() => toggleReadyProjectId(item)}
                checked={readyProjectIds.includes(item)}
              />
            ))}
            <br />
            Do you want to proceed with merge?
          </div>
        }
      />
    </>
  );
}

export const mapStateToProps = state => {
  return {
    [REPORT_LIST_DATA_KEY]: {
      data: null,
      isFetching: false,
      error: null,
      ...state.viewData[REPORT_LIST_DATA_KEY],
    },
    [GET_ALL_REPORTS_PERIODS]: {
      data: null,
      isFetching: false,
      error: null,
      ...state.viewData[GET_ALL_REPORTS_PERIODS],
    },
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatchFetch: {
      [REPORT_LIST_DATA_KEY]: dashboard_type => {
        dispatch(
          requestAPI(REPORT_LIST_DATA_KEY, null, {
            data: {
              period: ownProps.match.params.year || '',
              dashboard_type,
            },
          }),
        );
      },
      [GET_ALL_REPORTS_PERIODS]: () => {
        dispatch(requestAPI(GET_ALL_REPORTS_PERIODS, null, {}));
      },
    },
    getPublicationList: period => {
      dispatch(resetReportsList());
      dispatch(getPublicationListRequest({ period }));
    },
    flushReportsListData: () => {
      dispatch(clearApiKeys([REPORT_LIST_DATA_KEY]));
    },
    fetchAppConf: () => {
      dispatch(requestAPI(GET_APP_CONFIGURATION));
    },
  };
};

const ReportsList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsListContainer);
export default ReportsList;
